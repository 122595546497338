import { useNotificationStore } from '~/stores/notification';

export const useLoaderFetch: typeof useFetch = (request, opts?) => {
    const config = useRuntimeConfig()
    const store = useFormsStore();
    const notificationStore = useNotificationStore();

    let hasLoader = false;
    const loadingRef = ref<typeof GibsControlLoading>();
    if (store.getLoader() != undefined || store.getLoader() != null)
    {
        loadingRef.value = store.getLoader().value;
        if (loadingRef.value != undefined || loadingRef.value != null)
        {
            hasLoader = true;
        }
    }
    let _request = {request: `[${opts?.method??'GET'}]${request}`, date: new Date(), 'status': 'Init' };
    let exists = false;
    if (hasLoader === true) {
        exists = !loadingRef.value.setFetchData(_request);
    }
    if (exists == true){
        return null;
    }
    else 
    {
        // BEGIN : NOTE this is required to prevent the fetch to be executed when any changes are happening on reference objects aka Reactive Objects
        if (opts != null && opts.body !== null)
        {
            opts['watch'] = false;
        }
        else
        {
            opts = { watch: false };
        }
        // END : NOTE this is required to prevent the fetch to be executed when any changes are happening on reference objects aka Reactive Objects

        return useFetch(request, 
        { 
            async onRequest({ request, options }) {     
                if (hasLoader === true)
                {
                    _request['status'] = 'onRequest';
                }
            },
            async onRequestError({ request, options, error }) {                
                if (hasLoader === true)
                {
                    _request['status'] = 'onRequestError';
                    loadingRef.value.removeFetchData(_request);                    
                }

                throw new ApiResponseError(response, request);
            },
            async onResponse({ request, response, options }) {
                if (hasLoader === true)
                {
                    _request['status'] = 'onResponse';
                    loadingRef.value.removeFetchData(_request);
                }
            },
            async onResponseError({ request, response, options }) {
                
                if (hasLoader === true)
                {
                    _request['status'] = 'onResponseError';
                    loadingRef.value.removeFetchData(_request);
                }
                
                throw new ApiResponseError(response, request);
            },
            ...opts 
        })
    }
}

export const $useLoaderFetch: typeof useLoaderFetch = (request, opts?) => {
    return useLoaderFetch(request,opts)
    .then((response) => {
        return response.data.value;
    });
}

